import type { NextPage } from "next";
import Head from "next/head";
import { Button } from "../components/common/button";
import { H1 } from "../components/common/h1";
import { Logo } from "../components/common/logo";
import { VerticalText } from "../components/common/verticalText";
import styled from "@emotion/styled";
import { m, motion } from "framer-motion";
import { css } from "@emotion/react";
import { breakpoints, mq } from "../styles/mq";

const Home: NextPage = () => {
	const Content = styled("div")`
		position: absolute;
		overflow: hidden;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		${mq(breakpoints.md)} {
			display: grid;
			align-items: initial;
			grid-template-columns: 1fr 5fr;
		}
		${mq(breakpoints.lg)} {
			grid-template-columns: 5fr 1fr 5fr;
		}
		grid-template-rows: 1fr;
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		.intro-section {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 50px;
			.intro-text {
				font-size: 0.8rem;
				${mq(breakpoints.sm)} {
					font-size: 1.2rem;
				}
				.intro-description {
					letter-spacing: 2px;
					font-weight: 300;
					line-height: 1.5em;
					width: 80%;
					color: #bdbdbd;
					margin-bottom: 50px;
				}
			}
		}
	`;

	const VerticalTextContainer = styled("div")`
		display: none;
		${mq(breakpoints.md)} {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	`;

	const BackgroundImage = styled("div")`
		display: none;
		background-size: cover;
		background-position: center;
		${mq(breakpoints.lg)} {
			display: block;
		}
	`;

	return (
		<>
			<Head>
				<title>Quality at the forefront | Moonsworth</title>
				<link rel="icon" href="/favicon.ico" />
			</Head>

			<main>
				<Content>
					<BackgroundImage
						css={css`
							background-image: url("/images/homepage.webp");
						`}
					/>
					<VerticalTextContainer>
						<VerticalText text="Moonsworth" top="400px" />
					</VerticalTextContainer>
					<section className="intro-section">
						<div className="intro-text">
							<motion.div
								initial={{ opacity: 0, y: -50 }}
								animate={{
									opacity: 1,
									y: 0,
								}}
								transition={{
									duration: 0.8,
									delay: 0.1,
									type: "spring",
									bounce: 0,
								}}
							>
								<H1 tagline="Quality at the forefront">
									At Moonsworth, the possibilities are
									endless.
								</H1>
							</motion.div>
							<motion.div
								initial={{ opacity: 0, y: -50 }}
								animate={{
									opacity: 1,
									y: 0,
								}}
								transition={{
									duration: 0.8,
									delay: 0.2,
									type: "spring",
									bounce: 0,
								}}
							>
								<p className="intro-description">
									We are a software development agency that
									specializes in game development and digital
									marketing. We take pride in the quality of
									our products and creativity of our team.
								</p>
							</motion.div>
							<motion.div
								initial={{ opacity: 0, y: -50 }}
								animate={{
									opacity: 1,
									y: 0,
								}}
								transition={{
									duration: 0.8,
									delay: 0.4,
									type: "spring",
									bounce: 0,
								}}
							>
								<Button href="/about">Explore</Button>
							</motion.div>
						</div>
					</section>
				</Content>
			</main>
		</>
	);
};

export default Home;
